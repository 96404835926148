import { PageRendererProps } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'
import React from 'react'
import styled from 'styled-components'

import consultImage from '../../assets/static/consult/consult6.jpg'
import dentalImplantTableImage from '../../assets/static/service/dentalImplant_table.png'
import dentalImplant1Image from '../../assets/static/service/dentalImplant1.png'
import dentalImplant2Image from '../../assets/static/service/dentalImplant2.png'
import dentalImplant3Image from '../../assets/static/service/dentalImplant3.png'
import ServiceLayout from '../../components/Layout/ServiceLayout'
import Seo from '../../components/Seo'
import { ContentTitle } from '../../components/Titles/ContentTitle'
import { space } from '../../constants/length'
import { H3 } from '../../constants/newText'
import Collapse from './components/Collapse'
import ContentSection from './components/ContentSection'
import ListItem from './components/ListItem'

const StyledContentTitle = styled(ContentTitle)`
  margin-bottom: ${space.l}px;
`
const ListGroup = styled.div`
  margin-top: ${space.m}px;
`
const StepTitle = styled.h3`
  ${H3}
  text-align: left;
  margin-bottom: ${space.xs + space.s}px;
`
const QuestionSection = styled.div`
  margin-top: ${space.l * 2}px;
`

/** 人工植牙 */
const DentalImplant = (props: PageRendererProps) => {
  const { formatMessage } = useIntl()

  const routes = [
    {
      label: formatMessage({ id: 'breadcrumb.index' }),
      path: '/'
    },
    {
      label: formatMessage({ id: 'service.index' }),
      path: '/service/digital'
    },
    {
      label: formatMessage({ id: 'service.dentalImplant' }),
      path: '/service/dentalImplant'
    }
  ]

  return (
    <ServiceLayout routes={routes} consultImage={consultImage} {...props} pageBannerInfo={formatMessage({ id: 'banner.dentalImplant' })}>
      <Seo
        title='缺牙怎麼辦？All-on-4 全口重建植牙專科團隊-日不落牙醫集團'
        metaDescription='牙齦萎縮，假牙戴不住？長期缺牙很久沒好好吃飯？日不落牙醫集團擁有台北、新竹、台中、台南的權威級醫師團隊搭配先進醫療設施，提供高規格的植體如同真牙般舒適安心，植牙手術安全無疑慮。'
      />
      <StyledContentTitle>專業{formatMessage({ id: 'service.dentalImplant' })}</StyledContentTitle>
      <ContentSection title='缺牙怎麼辦?'>
        <p>
          當你遇到蛀牙蛀太深、牙周病太嚴重，醫師已經宣告牙齒搶救不治必須拔除的時候，這時候我們就要選擇如何填補這個空缺的位置。常見的缺牙補牙方式：『<strong>牙橋</strong>
          』和『<strong>人工植牙</strong>』。
        </p>
        <p>
          牙橋，是將缺牙的左右兩顆牙齒磨小，好讓這兩顆牙齒背負著缺牙的罪孽，繼續咀嚼工作下去。人工植牙，則是植入一根人工植體當成被拔除的牙根，再用贋復技術從植體上方長出一顆假牙，恢復原本缺牙處的功能。
        </p>
      </ContentSection>
      <ContentSection imageUrl={dentalImplantTableImage} imageAlt='人工植牙與牙橋的優缺點' imagePosition='fluid' title='人工植牙與牙橋的優缺點' borderInvisible />
      <ContentSection title='缺牙不補？當心全口缺牙'>
        <p>因為缺牙不補牙，牙床有可能會爛掉，長期缺牙造成以下狀況：</p>
        <ListGroup>
          <ListItem content='缺牙處牙床會嚴重累積細菌，產生疾病' />
          <ListItem content='牙齒移位，牙縫越來越大' />
          <ListItem content='咀嚼會受到影響，臉型不對稱' />
          <ListItem content='說話（發音）不清楚' />
        </ListGroup>
      </ContentSection>
      <ContentSection imageUrl={dentalImplant1Image} imageAlt='第一步：術前評估骨頭狀況' title='人工植牙三步驟' borderInvisible>
        <StepTitle>第一步：術前評估骨頭狀況</StepTitle>
        <p>人工植牙成功與否，『骨頭』扮演很重要的角色，所以在植牙手術前會先進行CT斷層影像攝影，對牙齦底下的骨頭進行一個全盤性的檢查：骨密度、骨頭高度/寬度、骨頭形狀等。</p>
        <p>
          藉由上述資訊，醫師才能決定牙根植入的角度、深度、軸向，甚至搭配牙齒型態、比例、咬合關係等，提出一份最適合您的缺牙重建評估。畢竟植牙仍是個小手術、存在相當的風險，
          <strong>充分的醫病溝通</strong>是植牙成功的不二法門。
        </p>
      </ContentSection>
      <ContentSection imageUrl={dentalImplant2Image} imageAlt='第二步：植入植體，靜待骨整合' borderInvisible>
        <StepTitle>第二步：植入植體，靜待骨整合</StepTitle>
        <p>
          待決定好植入途徑後，醫師利用『手術導引版』迅速、確實地將植體鑽入事先規劃好的位置。待3~6個月的骨整合時期，讓植體與齒槽骨緊密結合後，這時我們就可準備接出人工牙冠準備製作上方假牙了。
        </p>
      </ContentSection>
      <ContentSection imageUrl={dentalImplant3Image} imageAlt='第三步：假牙重建，定期追蹤' borderInvisible>
        <StepTitle>第三步：假牙重建，定期追蹤</StepTitle>
        <p>最後，在植體上面接出支台齒，並依據牙齒型態、齒色、咬合關係等設計最適合您的全瓷冠，讓重建的牙齒看起來就跟鄰牙一般自然又漂亮。</p>
        <p>
          完成後的植牙，建議<strong>每3-6個月定期回診追蹤</strong>，同時維持良好的口腔衛生習慣，才能保持植牙的健康、並延長其使用壽命。
        </p>
      </ContentSection>
      <QuestionSection>
        <ContentSection title='人工植牙 常見問題'>
          <Collapse title='植牙後，假牙可以使用多久?'>
            <p>
              植牙使用年限與口腔衛生習慣息息相關，人工牙根本身雖不會蛀牙，但若牙周照護不好，還是會有植體牙周炎的問題。因人工牙根周圍沒有神經，所以發生<strong>植體牙周炎</strong>
              往往不會感到不適，非常容易輕忽此一問題。若沒有<strong>定期3~6個月回診檢查</strong>，恐造成植體掉落、植牙失敗等嚴重問題。
            </p>
          </Collapse>
          <Collapse title='什麼情況不適合植牙?'>
            <p>❌ 牙周狀況極度不佳者</p>
            <p>❌ 缺牙時間過長，導致齒槽骨過度萎縮者</p>
            <p>❌ 嚴重心血管疾病、糖尿病、凝血功能不佳者</p>
            <p>❌ 化療放射線療法病史、頭頸部癌症病史</p>
            <p>❌ 重度抽菸、嚼檳榔</p>
          </Collapse>
        </ContentSection>
      </QuestionSection>
    </ServiceLayout>
  )
}

export default DentalImplant
